<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
  <div class="container">
    <a class="navbar-brand js-scroll-trigger" href="#page-top">InfoDynamix</a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto my-2 my-lg-0">
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Services</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#portfolio">Portfolio</a></li>
        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
      </ul>
    </div>
  </div>
</nav>
<!-- Masthead-->
<header class="masthead">
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
      <div class="col-lg-10 align-self-end">
        <h1 class="text-uppercase text-white font-weight-bold">Web and Mobile Application Development</h1>
        <hr class="divider my-4" />
      </div>
      <div class="col-lg-8 align-self-baseline">
        <p class="text-white-75 font-weight-light mb-5">At InfoDynamix we work closely with our clients to develop world class web and mobile applications</p>
        <a class="btn btn-primary btn-xl js-scroll-trigger" href="#about">Find Out More</a>
      </div>
    </div>
  </div>
</header>
<!-- About-->
<section class="page-section bg-primary" id="about">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 text-center">
        <h2 class="text-white mt-0">We've got what you need!</h2>
        <hr class="divider light my-4" />
        <p class="text-white-50 mb-4">Wether your project is large or small, mobile or web. We at InfoDynamix have the determination and skills to deliver the solution you need, beyond your expectations and on time.</p>
        <a class="btn btn-light btn-xl js-scroll-trigger" href="#services">Get Started!</a>
      </div>
    </div>
  </div>
</section>
<!-- Services-->
<section class="page-section" id="services">
  <div class="container">
    <h2 class="text-center mt-0">At Your Service</h2>
    <hr class="divider my-4" />
    <div class="row">
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-gem text-primary mb-4"></i>
          <h3 class="h4 mb-2">Quality Service</h3>
          <p class="text-muted mb-0">Proven high quality of service with clients large and small.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
          <h3 class="h4 mb-2">Controlled by You</h3>
          <p class="text-muted mb-0">All our solutions are crafted to allow you to be in control.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-globe text-primary mb-4"></i>
          <h3 class="h4 mb-2">Global Reach</h3>
          <p class="text-muted mb-0">We work with global partners to get your solution right.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="mt-5">
          <i class="fas fa-4x fa-heart text-primary mb-4"></i>
          <h3 class="h4 mb-2">Always There to Help</h3>
          <p class="text-muted mb-0">We are always there to help you from project start, completion and beyond.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Portfolio-->
<div id="portfolio">
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/1.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/1.jpg" alt="" />
          <div class="portfolio-box-caption">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/2.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/2.jpg" alt="" />
          <div class="portfolio-box-caption">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/3.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/3.jpg" alt="" />
          <div class="portfolio-box-caption">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/4.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/4.jpg" alt="" />
          <div class="portfolio-box-caption">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/5.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/5.jpg" alt="" />
          <div class="portfolio-box-caption">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="assets/img/portfolio/fullsize/6.jpg">
          <img class="img-fluid" src="assets/img/portfolio/thumbnails/6.jpg" alt="" />
          <div class="portfolio-box-caption p-3">
            <div class="project-category text-white-50">Category</div>
            <div class="project-name">Project Name</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Call to action-->
<section class="page-section bg-dark text-white">
  <div class="container text-center">
    <h2 class="mb-4">Our Clients</h2>
    <p>
      Our clients are global. InfoDynamix carried out successful projects for companies both large and small including Crain Communications, South West Water, RWE Trading, ALD Automotive and the 2 Minute Foundation.
    </p>
<!--    <a class="btn btn-light btn-xl" href="https://startbootstrap.com/theme/creative/">Download Now!</a>-->
  </div>
</section>
<!-- Contact-->
<section class="page-section" id="contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 text-center">
        <h2 class="mt-0">Let's Get In Touch!</h2>
        <hr class="divider my-4" />
        <p class="text-muted mb-5">Ready to start your next project with us? Give us a call or send us an email and we will get back to you as soon as possible!</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
        <i class="fas fa-phone fa-3x mb-3 text-muted"></i>
        <div>07917 843820 </div>
      </div>
      <div class="col-lg-4 mr-auto text-center">
        <i class="fas fa-envelope fa-3x mb-3 text-muted"></i>
        <!-- Make sure to change the email address in BOTH the anchor text and the link target below!-->
        <a class="d-block" href="mailto:alan@infodynamix.co.uk">alan@infodynamix.co.uk</a>
      </div>
    </div>
  </div>
</section>
<!-- Footer-->
<footer class="bg-light py-5">
  <div class="container"><div class="small text-center text-muted">Copyright © 2021 - InfoDynamix Limited</div></div>
</footer>
